<template>
  <div class="asset">
    <div class="flex items-center justify-between gap-8">
      <pre>
        {{ asset }}
      </pre>
      <button
          class="h-12 w-12"
          @click="onChange"
      >+</button>
      <button
          class="h-12 w-12 bg-red-500 text-white"
          @click="onDelete"
      >x</button>
    </div>
  </div>
</template>

<script>
import {state} from "@/store";
import {capitalize, computed} from "vue";
import {useStore} from "vuex";
import {DELETE_ASSET, PUT_ASSET} from "@/store/operations";

export default {
  name: "Asset.vue",
  props: {
    dataType: {
      type: String,
      required: true,
      validator: v => v && capitalize(v) === v
    },
    id: {
      type: String,
      required: true
    },
  },
  setup(props) {
    const store = useStore()
    const asset = computed(() =>
        Object.values(state.assets[props.dataType] ?? {})
            .find(a =>
                a.dataType === props.dataType &&
                a.id === props.id
            )
    )
    const onChange = () => {
      asset.value.counter = (asset.value.counter || 0) + 1
      store.dispatch(PUT_ASSET, asset.value)
    }
    const onDelete = () => store.dispatch(DELETE_ASSET, asset.value)
    return {
      asset,
      onChange,
      onDelete,
    }
  }
}
</script>

<style scoped>

</style>
